/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { BinaryFileListResource, BinaryFileService } from '@alfa-client/binary-file-shared';
import { CommandOrder, CommandResource, CommandService, CreateCommand, isDone } from '@alfa-client/command-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import {
  createEmptyStateResource,
  createStateResource,
  doIfLoadingRequired,
  EMPTY_STRING,
  isLoaded,
  isNotEmpty,
  isNotNull,
  StateResource,
} from '@alfa-client/tech-shared';
import { VorgangResource, VorgangService, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { hasLink, Resource, ResourceUri } from '@ngxp/rest';
import { isNil } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { KommentarLinkRel, KommentarListLinkRel } from './kommentar.linkrel';
import { Kommentar, KOMMENTAR_UPLOADED_ATTACHMENTS, KommentarListResource, KommentarResource } from './kommentar.model';
import { KommentarRepository } from './kommentar.repository';

@Injectable({ providedIn: 'root' })
export class KommentarService {
  readonly kommentarList$: BehaviorSubject<StateResource<KommentarListResource>> = new BehaviorSubject(
    createEmptyStateResource<KommentarListResource>(),
  );
  readonly formularVisibility$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly _currentlyEdited$: BehaviorSubject<ResourceUri> = new BehaviorSubject(EMPTY_STRING);

  private navigationSub: Subscription;

  vorgangSubscription: Subscription;

  shouldReload: boolean = false;

  constructor(
    private repository: KommentarRepository,
    private commandService: CommandService,
    private navigationService: NavigationService,
    private vorgangService: VorgangService,
    private binaryFileService: BinaryFileService,
  ) {
    this.listenToNavigation();
    this._listenToVorgangChange();
  }

  private listenToNavigation(): void {
    this.unsubscribe();
    this.navigationSub = this.navigationService.urlChanged().subscribe((params: Params) => this._onNavigation(params));
  }

  private unsubscribe(): void {
    if (!isNil(this.navigationSub)) this.navigationSub.unsubscribe();
  }

  _onNavigation(params: Params): void {
    this.clearUploadedFiles();
    this.hideFormular();
    if (NavigationService.isVorgangListPage(params)) {
      this.setKommentarListOnReload();
    }
    if (NavigationService.isVorgangDetailPage(params, VorgangService.VORGANG_WITH_EINGANG_URL)) {
      this.setKommentarListOnReload();
    }
  }

  private setKommentarListOnReload(): void {
    this.kommentarList$.next({ ...this.kommentarList$.value, reload: true });
  }

  public getKommentareByVorgang(vorgang: VorgangResource): Observable<StateResource<KommentarListResource>> {
    this._refreshVorgangSubscription();
    doIfLoadingRequired(this.kommentarList$.value, () => this.loadKommentare(vorgang));
    return this.kommentarList$.asObservable();
  }

  _refreshVorgangSubscription(): void {
    this.vorgangSubscription.unsubscribe();
    this._listenToVorgangChange();
  }

  _listenToVorgangChange(): void {
    this.vorgangSubscription = this.vorgangService
      .getVorgangWithEingang()
      .subscribe((vorgangWithEingangStateResource: StateResource<VorgangWithEingangResource>) =>
        this._handleVorgangChange(vorgangWithEingangStateResource),
      );
  }

  _handleVorgangChange(vorgangWithEingangStateResource: StateResource<VorgangWithEingangResource>): void {
    if (this.shouldReloadList(vorgangWithEingangStateResource)) {
      this.shouldReload = true;
    }
    if (isLoaded(vorgangWithEingangStateResource) && this.shouldReload) {
      this._setKommentarListReload();
      this.shouldReload = false;
    }
  }

  private shouldReloadList(vorgangWithEingangStateResource: StateResource<VorgangWithEingangResource>): boolean {
    return (
      (vorgangWithEingangStateResource.loading || vorgangWithEingangStateResource.reload) &&
      isNotNull(this.kommentarList$.value.resource)
    );
  }

  private loadKommentare(vorgang: VorgangResource): void {
    this._setListLoadingTrue();

    const sub: Subscription = this.repository.findKommentare(vorgang).subscribe((kommentarList: KommentarListResource) => {
      this._setKommentarList(kommentarList);
      sub.unsubscribe();
    });
  }

  _setListLoadingTrue(): void {
    this.kommentarList$.next({ ...this.kommentarList$.value, loading: true });
  }

  _setKommentarListReload() {
    this.kommentarList$.next({ ...this.kommentarList$.value, reload: true });
  }

  _setKommentarList(kommentarList: KommentarListResource): void {
    this.kommentarList$.next(createStateResource(kommentarList));
  }

  public isFormularVisible(): Observable<boolean> {
    return combineLatest([this.formularVisibility$, this._currentlyEdited$]).pipe(
      map(([isVisible, currentlyEdited]) => isVisible && currentlyEdited === EMPTY_STRING),
    );
  }

  public canCreateNewKommentar(kommentareListResource: KommentarListResource): Observable<boolean> {
    return this.formularVisibility$.pipe(
      map((formularVisibility) => !formularVisibility && hasLink(kommentareListResource, KommentarListLinkRel.CREATE_KOMMENTAR)),
    );
  }

  public showFormular(): void {
    this.setCurrentlyEdited(EMPTY_STRING);
    this.formularVisibility$.next(true);
  }

  public hideFormular(): void {
    this.formularVisibility$.next(false);
  }

  public createKommentar(kommentar: Kommentar): Observable<StateResource<CommandResource>> {
    return this._createKommentarCommand(
      this.kommentarList$.value.resource,
      KommentarListLinkRel.CREATE_KOMMENTAR,
      this._createCreateKommentarCommand(kommentar),
    );
  }

  _createCreateKommentarCommand(kommentar: Kommentar): CreateCommand {
    return { order: CommandOrder.CREATE_KOMMENTAR, body: kommentar };
  }

  public editKommentar(kommentar: KommentarResource, toPatch: Kommentar): Observable<StateResource<CommandResource>> {
    return this._createKommentarCommand(kommentar, KommentarLinkRel.EDIT, this._createEditKommentarCommand(toPatch));
  }

  _createEditKommentarCommand(kommentar: Kommentar): CreateCommand {
    return { order: CommandOrder.EDIT_KOMMENTAR, body: kommentar };
  }

  _createKommentarCommand(
    resource: Resource,
    linkRel: string,
    command: CreateCommand,
  ): Observable<StateResource<CommandResource>> {
    return this.commandService.createCommand(resource, linkRel, command).pipe(
      tap((createdCommand: StateResource<CommandResource>) => this._afterCreateOrEditKommentar(createdCommand)),
      startWith(createEmptyStateResource<CommandResource>(true)),
    );
  }

  _afterCreateOrEditKommentar(command: StateResource<CommandResource>): void {
    if (isDone(command.resource)) {
      this.hideFormular();
      this._setKommentarListReload();
      this.vorgangService.reloadCurrentVorgang();
    }
  }

  _getAttachments(kommentar: KommentarResource): Observable<StateResource<BinaryFileListResource>> {
    if (hasLink(kommentar, KommentarLinkRel.ATTACHMENTS)) {
      return this.binaryFileService.getFiles(kommentar, KommentarLinkRel.ATTACHMENTS);
    }
    return of(createEmptyStateResource<BinaryFileListResource>());
  }

  public clearUploadedFiles(): void {
    this.binaryFileService.clearUploadedFiles(KOMMENTAR_UPLOADED_ATTACHMENTS);
  }

  public getCurrentlyEdited(): Observable<ResourceUri> {
    return this._currentlyEdited$.asObservable();
  }

  public setCurrentlyEdited(resourceUri: ResourceUri): void {
    this.clearUploadedFiles();
    if (isNotEmpty(resourceUri)) {
      this.hideFormular();
    }
    this._currentlyEdited$.next(resourceUri);
  }
}
