import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-forwarding-item-info',
  standalone: true,
  template: `<p class="font-medium">{{ label }}</p>
    <p>{{ address }}</p>`,
})
export class ForwardingItemInfoComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) address!: string;
}
