import { Component } from '@angular/core';

@Component({
  selector: 'ods-dialog-container',
  standalone: true,
  imports: [],
  template: `
    <div class="static flex w-[calc(100vw-2rem)] justify-center">
      <div class="flex max-w-4xl grow flex-col rounded-lg bg-background-50 p-6 shadow-md">
        <ng-content />
      </div>
    </div>
  `,
})
export class DialogContainerComponent {}
