/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiRootLinkRel } from '@alfa-client/api-root-shared';
import { getFilterFromLocalStorage, getViewFromLocalStorage } from '@alfa-client/app-shared';
import { RouteData } from '@alfa-client/navigation-shared';
import { EMPTY_STRING, isNotEmpty, isNotNull, isNotUndefined } from '@alfa-client/tech-shared';
import { isEmpty, isUndefined } from 'lodash-es';
import { VorgangState } from './+state/vorgang.reducer';
import { VorgangFilter, VorgangView } from './vorgang.model';

import * as VorgangNavigationUtil from './vorgang-navigation.util';

export const MEINE_ROUTE_PARAM: string = 'meine';
export const ALLE_ROUTE_PARAM: string = 'alle';
export const UNASSIGNED_ROUTE_PARAM: string = 'unassigned';

export const NEU_ROUTE_PARAM: string = 'neu';
export const ANGENOMMEN_ROUTE_PARAM: string = 'angenommen';
export const IN_BEARBEITUNG_ROUTE_PARAM: string = 'in_bearbeitung';
export const BESCHIEDEN_ROUTE_PARAM: string = 'beschieden';
export const ABGESCHLOSSEN_ROUTE_PARAM: string = 'abgeschlossen';
export const WEITERGELEITET_ROUTE_PARAM: string = 'weitergeleitet';
export const VERWORFEN_ROUTE_PARAM: string = 'verworfen';
export const WIEDERVORLAGEN_ROUTE_PARAM: string = 'wiedervorlagen';
export const UNGELESENE_NACHRICHTEN_ROUTE_PARAM: string = 'ungelesene_nachrichten';
export const ZU_LOESCHEN_ROUTE_PARAM: string = 'zu_loeschen';
export const SEARCH_ROUTE_PARAM: string = 'search';

export const VORGANG_WITH_EINGANG_ROUTE_PARAM: string = 'vorgangWithEingangUrl';

export const ROUTE_PARAM_BY_VORGANG_VIEW: { [view: string]: string } = {
  [VorgangView.NEU]: NEU_ROUTE_PARAM,
  [VorgangView.ANGENOMMEN]: ANGENOMMEN_ROUTE_PARAM,
  [VorgangView.IN_BEARBEITUNG]: IN_BEARBEITUNG_ROUTE_PARAM,
  [VorgangView.BESCHIEDEN]: BESCHIEDEN_ROUTE_PARAM,
  [VorgangView.ABGESCHLOSSEN]: ABGESCHLOSSEN_ROUTE_PARAM,
  [VorgangView.WEITERGELEITET]: WEITERGELEITET_ROUTE_PARAM,
  [VorgangView.VERWORFEN]: VERWORFEN_ROUTE_PARAM,
  [VorgangView.VORGANG_LIST]: EMPTY_STRING,
  [VorgangView.WIEDERVORLAGEN]: WIEDERVORLAGEN_ROUTE_PARAM,
  [VorgangView.UNGELESENE_NACHRICHTEN]: UNGELESENE_NACHRICHTEN_ROUTE_PARAM,
  [VorgangView.ZU_LOESCHEN]: ZU_LOESCHEN_ROUTE_PARAM,
  [VorgangView.SEARCH]: SEARCH_ROUTE_PARAM,
};

export const VORGANG_VIEW_BY_ROUTE_PARAM: { [routeParam: string]: VorgangView } = {
  [NEU_ROUTE_PARAM]: VorgangView.NEU,
  [ANGENOMMEN_ROUTE_PARAM]: VorgangView.ANGENOMMEN,
  [IN_BEARBEITUNG_ROUTE_PARAM]: VorgangView.IN_BEARBEITUNG,
  [BESCHIEDEN_ROUTE_PARAM]: VorgangView.BESCHIEDEN,
  [ABGESCHLOSSEN_ROUTE_PARAM]: VorgangView.ABGESCHLOSSEN,
  [WEITERGELEITET_ROUTE_PARAM]: VorgangView.WEITERGELEITET,
  [VERWORFEN_ROUTE_PARAM]: VorgangView.VERWORFEN,
  [WIEDERVORLAGEN_ROUTE_PARAM]: VorgangView.WIEDERVORLAGEN,
  [UNGELESENE_NACHRICHTEN_ROUTE_PARAM]: VorgangView.UNGELESENE_NACHRICHTEN,
  [ZU_LOESCHEN_ROUTE_PARAM]: VorgangView.ZU_LOESCHEN,
  [SEARCH_ROUTE_PARAM]: VorgangView.SEARCH,
};

export const ROUTE_PARAM_BY_VORGANG_FILTER: { [filter: string]: string } = {
  [VorgangFilter.ALLE]: ALLE_ROUTE_PARAM,
  [VorgangFilter.MEINE_VORGAENGE]: MEINE_ROUTE_PARAM,
  [VorgangFilter.NICHT_ZUGEWIESEN]: UNASSIGNED_ROUTE_PARAM,
};

export const VORGANG_FILTER_BY_ROUTE_PARAM: { [routeParam: string]: VorgangFilter } = {
  [ALLE_ROUTE_PARAM]: VorgangFilter.ALLE,
  [MEINE_ROUTE_PARAM]: VorgangFilter.MEINE_VORGAENGE,
  [UNASSIGNED_ROUTE_PARAM]: VorgangFilter.NICHT_ZUGEWIESEN,
};

const SEARCH_LINK_BY_VORGANG_FILTER: { [filter: string]: string } = {
  [VorgangFilter.MEINE_VORGAENGE]: ApiRootLinkRel.SEARCH_MEINE,
  [VorgangFilter.ALLE]: ApiRootLinkRel.SEARCH_ALLE,
  [VorgangFilter.NICHT_ZUGEWIESEN]: ApiRootLinkRel.SEARCH_UNASSIGNED,
};

export function getSearchString(routeData: RouteData): string {
  const searchString: string = VorgangNavigationUtil.getRouteUrlSegment(routeData, 2);
  return isUndefined(searchString) ? EMPTY_STRING : decodeURIComponent(searchString);
}

export function getSearchLinkRel(vorgangFilter: VorgangFilter): string {
  return SEARCH_LINK_BY_VORGANG_FILTER[vorgangFilter];
}

export function getVorgangFilter(routeData: RouteData): VorgangFilter {
  return VorgangNavigationUtil.isUebersichtsSeite(routeData) ?
      VORGANG_FILTER_BY_ROUTE_PARAM[getRouteUrlSegment(routeData, 0)]
    : undefined;
}

export function isViewSelected(routeData: RouteData, vorgangView: VorgangView): boolean {
  return VorgangNavigationUtil.getVorgangView(routeData) === vorgangView;
}

export function getVorgangView(routeData: RouteData): VorgangView {
  const vorgangViewValue: string = VorgangNavigationUtil.getRouteUrlSegment(routeData, 1);
  if (VorgangNavigationUtil.isUebersichtsSeite(routeData)) {
    return isNotUndefined(vorgangViewValue) ? VORGANG_VIEW_BY_ROUTE_PARAM[vorgangViewValue] : VorgangView.VORGANG_LIST;
  }
  return undefined;
}

export function isUebersichtsSeite(routeData: RouteData): boolean {
  const filter: string = VorgangNavigationUtil.getRouteUrlSegment(routeData, 0);
  return (
    isNotUndefined(filter) &&
    (filter === ROUTE_PARAM_BY_VORGANG_FILTER[VorgangFilter.ALLE] ||
      filter === ROUTE_PARAM_BY_VORGANG_FILTER[VorgangFilter.MEINE_VORGAENGE] ||
      filter === ROUTE_PARAM_BY_VORGANG_FILTER[VorgangFilter.NICHT_ZUGEWIESEN])
  );
}

export function getRouteUrlSegment(routeData: RouteData, num: number): string {
  return routeData.urlSegments.length > num ? routeData.urlSegments[num].path.toString() : undefined;
}

export function buildBackButtonUrl(state: VorgangState): string {
  if (VorgangNavigationUtil.isStateEqualLocalStorage(state)) {
    return VorgangNavigationUtil.buildVorgangListRouteWithVorgangFilter(state, state.vorgangFilter);
  }

  return '/' + VorgangNavigationUtil.buildPathSegmentsFromLocalStorage().join('/');
}

export function isStateEqualLocalStorage(state: VorgangState): boolean {
  return (
    getFilterFromLocalStorage() === ROUTE_PARAM_BY_VORGANG_FILTER[state.vorgangFilter] &&
    getViewFromLocalStorage() === ROUTE_PARAM_BY_VORGANG_VIEW[state.vorgangView]
  );
}

export function buildVorgangListRoutePathWithFilter(state: VorgangState, filter: VorgangFilter): string {
  return VorgangNavigationUtil.buildVorgangListRouteWithVorgangFilter(state, filter);
}

export function buildVorgangListRouteWithVorgangFilter(state: VorgangState, filter: VorgangFilter): string {
  let route = '/' + ROUTE_PARAM_BY_VORGANG_FILTER[filter];

  if (state.vorgangView !== VorgangView.VORGANG_LIST) {
    route += '/' + ROUTE_PARAM_BY_VORGANG_VIEW[state.vorgangView];
  }
  if (state.vorgangView === VorgangView.SEARCH && isNotEmpty(state.searchString)) {
    route += '/' + encodeURIComponent(state.searchString);
  }
  return route;
}

const VORGANG_LIST_LINK_REL_SUFFIX: { [filter: string]: string } = {
  [VorgangFilter.MEINE_VORGAENGE]: 'my',
  [VorgangFilter.ALLE]: 'all',
  [VorgangFilter.NICHT_ZUGEWIESEN]: 'unassigned',
};

export function buildLinkRel(vorgangFilter: VorgangFilter, vorgangView: VorgangView): string {
  const filterParam: string = VORGANG_LIST_LINK_REL_SUFFIX[vorgangFilter];

  if (vorgangView !== VorgangView.VORGANG_LIST) {
    return `vorgaenge_${ROUTE_PARAM_BY_VORGANG_VIEW[vorgangView]}_${filterParam}`;
  }
  return `vorgaenge_${filterParam}`;
}

export function buildLinkRelFromPathSegments(pathSegments: string[]): string {
  const vorgangFilter: VorgangFilter = VORGANG_FILTER_BY_ROUTE_PARAM[pathSegments[0]];

  if (pathSegments[1] === SEARCH_ROUTE_PARAM) {
    return getSearchLinkRel(vorgangFilter);
  }

  const vorgangView: VorgangView =
    isEmpty(pathSegments[1]) ? VorgangView.VORGANG_LIST : VORGANG_VIEW_BY_ROUTE_PARAM[pathSegments[1]];

  return buildLinkRel(vorgangFilter, vorgangView);
}

export function buildVorgangFilterViewRoutePath(
  vorgangFilter: VorgangFilter,
  vorgangView: VorgangView,
  searchString: string,
): string {
  let baseRoutePath: string = '/' + ROUTE_PARAM_BY_VORGANG_FILTER[vorgangFilter] + '/' + ROUTE_PARAM_BY_VORGANG_VIEW[vorgangView];
  if (isNotEmpty(searchString)) {
    return baseRoutePath + '/' + searchString;
  }
  return baseRoutePath;
}

export function buildPathSegmentsFromLocalStorage(): string[] {
  const pathSegments: string[] = [];
  const view: string = getViewFromLocalStorage();
  let filter: string = getFilterFromLocalStorage();

  filter = isEmpty(filter) ? ROUTE_PARAM_BY_VORGANG_FILTER[VorgangFilter.ALLE] : filter;
  pathSegments.push(filter);

  if (isNotEmpty(view)) {
    pathSegments.push(view);
  }

  return pathSegments;
}

export function isVorgangPage(routeData: RouteData): boolean {
  return hasSegements(routeData, 0) && hasVorgangParam(routeData);
}

function hasVorgangParam(routeData: RouteData): boolean {
  return isNotNull(routeData.queryParameter[VORGANG_WITH_EINGANG_ROUTE_PARAM]);
}

function hasSegements(routeData: RouteData, numberOfSegements: number): boolean {
  return routeData.urlSegments.length === numberOfSegements;
}
