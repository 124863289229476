import { ComponentType } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  inject,
  Injectable,
  Injector,
  Provider,
} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OzgCloudComponentFactory {
  private readonly appRef = inject(ApplicationRef);
  private readonly envInjector = inject(EnvironmentInjector);

  public createComponent<T>(
    componentType: ComponentType<any>,
    parentInjector: Injector,
    providers: Provider[] = [],
  ): ComponentRef<T> {
    const component: ComponentRef<any> = <ComponentRef<any>>createComponent(componentType, {
      environmentInjector: this.envInjector,
      elementInjector: this._createElementInjector(parentInjector, providers),
    });
    this._registerComponentToChangeDetection(component);
    return component;
  }

  _createElementInjector(parentInjector: Injector, providers: Provider[] = []): Injector {
    return Injector.create({ providers, parent: parentInjector });
  }

  _registerComponentToChangeDetection(component: ComponentRef<any>): void {
    this.appRef.attachView(component.hostView);
  }
}
