/*
 * Copyright (C) 2025 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { TooltipPosition } from './tooltip.directive';

@Component({
  selector: 'ods-tooltip',
  imports: [NgClass],
  template: `<span
    [attr.data-test-id]="dataTestId"
    class="tooltip fixed z-[100] animate-fadeIn cursor-default break-words rounded bg-ozggray-900 px-3 py-2 text-sm font-normal text-whitetext before:absolute before:border-l-[0.5rem] before:border-r-[0.5rem] before:border-l-transparent before:border-r-transparent dark:bg-white"
    [ngClass]="class"
    [class.visible]="show"
    [class.invisible]="!show"
    [class.text-wrap]="width"
    [class.text-nowrap]="!width"
    [style.left.px]="left"
    [style.top.px]="top"
    [style.width.rem]="width"
    [style.--before-left.px]="leftOffset"
    [attr.id]="id"
    role="tooltip"
  >
    {{ text }}
  </span>`,
  styles: [':host {@apply contents}'],
  styleUrl: './tooltip.component.scss',
  standalone: true,
})
export class TooltipComponent {
  text: string = '';
  left: number = 0;
  top: number = 0;
  width: number = null;
  show: boolean = false;
  id: string;
  class: string;
  leftOffset: number;

  dataTestId: string;

  set position(value: TooltipPosition) {
    if (value === TooltipPosition.ABOVE) {
      this.class =
        'mb-2 -translate-x-1/2 -translate-y-[calc(100%+0.5rem)] before:-bottom-2 before:border-t-[0.5rem] before:border-t-ozggray-900 dark:before:border-t-white';
    } else {
      this.class =
        'mt-2 -translate-x-1/2 before:-top-2 before:border-b-[0.5rem] before:border-b-ozggray-900 dark:before:border-b-white';
    }
  }
}
