/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { removeLocalStorageView, setFilterIntoStorage, setViewIntoStorage } from '@alfa-client/app-shared';
import { BinaryFileListResource, LoadBinaryFileListSuccessProps } from '@alfa-client/binary-file-shared';
import {
  CommandOrder,
  CommandProps,
  CommandResource,
  CommandStateResourceProps,
  CreateCommand,
  CreateCommandProps,
  LoadCommandListSuccessProps,
  getPendingCommandByOrder,
} from '@alfa-client/command-shared';
import { RouteData } from '@alfa-client/navigation-shared';
import {
  ApiErrorAction,
  EMPTY_STRING,
  StateResource,
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
  getApiErrorFromHttpErrorResponse,
} from '@alfa-client/tech-shared';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash-es';
import {
  ROUTE_PARAM_BY_VORGANG_FILTER,
  ROUTE_PARAM_BY_VORGANG_VIEW,
  getSearchString,
  getVorgangFilter,
  getVorgangView,
  isUebersichtsSeite,
  isVorgangPage,
} from '../vorgang-navigation.util';
import {
  StatusCommandMap,
  VorgangFilter,
  VorgangListResource,
  VorgangResource,
  VorgangStatistic,
  VorgangView,
  VorgangWithEingangResource,
} from '../vorgang.model';
import { getVorgaengeFromList, isAssignUserCommand, isStatusCommand } from '../vorgang.util';
import { HttpErrorAction, StringBasedProps, VorgangListAction, VorgangWithEingangAction } from './vorgang.actions';

import * as CommandActions from '../../../../command-shared/src/lib/+state/command.actions';
import * as NavigationActions from '../../../../navigation-shared/src/lib/+state/navigation.actions';
import * as VorgangActions from './vorgang.actions';
import * as VorgangReducer from './vorgang.reducer';

export const VORGANG_FEATURE_KEY = 'VorgangState';

export interface VorgangPartialState {
  readonly [VORGANG_FEATURE_KEY]: VorgangState;
}

export interface VorgangState {
  vorgangList: StateResource<VorgangListResource>;
  vorgaenge: VorgangResource[];
  vorgangStatistic: StateResource<VorgangStatistic>;
  searchString: string;
  searchPreviewList: StateResource<VorgangListResource>;
  vorgangView: VorgangView;
  vorgangFilter: VorgangFilter;

  vorgangWithEingang: StateResource<VorgangWithEingangResource>;
  attachmentList: StateResource<BinaryFileListResource>;
  representationList: StateResource<BinaryFileListResource>;
  assignUserCommand: StateResource<CommandResource>;
  forwardPendingCommand: StateResource<CommandResource>;
  sendPostfachNachrichtPendingCommand: StateResource<CommandResource>;
  statusCommandMap: StatusCommandMap;
  revokeCommand: StateResource<CommandResource>;
  vorgangExport: StateResource<boolean>;
}

export const initialState: VorgangState = {
  vorgangList: createEmptyStateResource(),
  vorgangStatistic: createStateResource(createEmptyVorgangStatistic()),
  vorgaenge: [],
  searchString: EMPTY_STRING,
  searchPreviewList: createEmptyStateResource(),
  vorgangView: VorgangView.VORGANG_LIST,
  vorgangFilter: VorgangFilter.ALLE,

  vorgangWithEingang: createEmptyStateResource(),
  attachmentList: createEmptyStateResource(),
  representationList: createEmptyStateResource(),
  assignUserCommand: createEmptyStateResource(),
  forwardPendingCommand: createEmptyStateResource(),
  sendPostfachNachrichtPendingCommand: createEmptyStateResource(),
  statusCommandMap: <any>{},
  revokeCommand: createEmptyStateResource(),
  vorgangExport: createEmptyStateResource(),
};

function createEmptyVorgangStatistic(): VorgangStatistic {
  return {
    byStatus: {
      abgeschlossen: null,
      angenommen: null,
      neu: null,
      inBearbeitung: null,
      beschieden: null,
      verworfen: null,
      weitergeleitet: null,
      zuLoeschen: null,
    },
    wiedervorlagen: null,
    existsWiedervorlageOverdue: false,
    ungeleseneNachrichten: 0,
  };
}

const vorgangReducer: ActionReducer<VorgangState, Action> = createReducer(
  initialState,

  //VorgangList
  on(
    VorgangActions.loadVorgangList,
    (state: VorgangState): VorgangState => ({
      ...state,
      vorgangList: { ...state.vorgangList, loading: true },
    }),
  ),
  on(VorgangActions.loadVorgangListSuccess, (state: VorgangState, action: VorgangListAction) => ({
    ...state,
    vorgangList: createStateResource<VorgangListResource>(action.vorgangList),
    vorgaenge: getVorgaengeFromList(action.vorgangList),
    vorgangStatistic: createStateResource(action.vorgangList.statistic),
    searchPreviewList: createEmptyStateResource<VorgangListResource>(),
  })),
  on(
    VorgangActions.loadVorgangListFailure,
    (state: VorgangState, action: ApiErrorAction): VorgangState => ({
      ...state,
      vorgangList: createErrorStateResource(action.apiError),
      searchPreviewList: createEmptyStateResource(),
    }),
  ),

  on(
    VorgangActions.loadNextPage,
    (state: VorgangState): VorgangState => ({
      ...state,
      vorgangList: { ...state.vorgangList, loading: true },
    }),
  ),
  on(
    VorgangActions.loadNextPageSuccess,
    (state, action: VorgangListAction): VorgangState => ({
      ...state,
      vorgangList: createStateResource<VorgangListResource>(action.vorgangList),
      vorgaenge: [...state.vorgaenge].concat(getVorgaengeFromList(action.vorgangList)),
      vorgangStatistic: createStateResource(action.vorgangList.statistic),
    }),
  ),

  //Search
  on(
    VorgangActions.searchVorgaengeBy,
    (state: VorgangState): VorgangState => ({
      ...state,
      vorgangList: { ...state.vorgangList, loading: true },
      vorgaenge: [],
      searchPreviewList: createEmptyStateResource(),
    }),
  ),
  on(
    VorgangActions.searchVorgaengeBySuccess,
    (state: VorgangState, action: VorgangListAction): VorgangState => ({
      ...state,
      vorgangList: createStateResource<VorgangListResource>(action.vorgangList),
      vorgaenge: getVorgaengeFromList(action.vorgangList),
      vorgangStatistic: createStateResource(action.vorgangList.statistic),
      searchPreviewList: createEmptyStateResource<VorgangListResource>(),
    }),
  ),
  on(
    VorgangActions.searchVorgaengeByFailure,
    (state: VorgangState, action: HttpErrorAction): VorgangState => ({
      ...state,
      vorgangList: createErrorStateResource(getApiErrorFromHttpErrorResponse(action.httpErrorResponse)),
      searchPreviewList: createEmptyStateResource(),
    }),
  ),

  on(
    VorgangActions.setSearchString,
    (state: VorgangState, props: StringBasedProps): VorgangState => ({
      ...state,
      searchString: props.string,
      searchPreviewList: clearPreviewList(props) ? createEmptyStateResource() : { ...state.searchPreviewList, reload: true },
    }),
  ),
  on(
    VorgangActions.searchForPreview,
    (state: VorgangState): VorgangState => ({
      ...state,
      searchPreviewList: { ...state.searchPreviewList, loading: true },
    }),
  ),
  on(
    VorgangActions.searchForPreviewSuccess,
    (state: VorgangState, action: VorgangListAction): VorgangState => ({
      ...state,
      searchPreviewList: createStateResource<VorgangListResource>(action.vorgangList),
    }),
  ),
  on(
    VorgangActions.searchForPreviewFailure,
    (state: VorgangState, action: HttpErrorAction): VorgangState => ({
      ...state,
      searchPreviewList: createErrorStateResource(getApiErrorFromHttpErrorResponse(action.httpErrorResponse)),
    }),
  ),

  on(
    VorgangActions.clearSearchPreviewList,
    (state: VorgangState): VorgangState => ({
      ...state,
      searchPreviewList: createEmptyStateResource(),
    }),
  ),

  on(
    VorgangActions.clearSearchString,
    (state: VorgangState): VorgangState => ({
      ...state,
      searchString: EMPTY_STRING,
    }),
  ),

  on(
    VorgangActions.setSearchString,
    (state: VorgangState, action: StringBasedProps): VorgangState => ({
      ...state,
      searchString: action.string,
    }),
  ),

  //VorgangWithEingang
  on(
    VorgangActions.loadVorgangWithEingang,
    (state: VorgangState): VorgangState => ({
      ...state,
      vorgangWithEingang: { ...state.vorgangWithEingang, loading: true },
    }),
  ),
  on(
    VorgangActions.loadVorgangWithEingangSuccess,
    (state: VorgangState, props: VorgangWithEingangAction): VorgangState => ({
      ...state,
      vorgangWithEingang: createStateResource(props.vorgangWithEingang),
      representationList: { ...state.representationList, reload: true },
      attachmentList: { ...state.attachmentList, reload: true },
    }),
  ),
  on(
    VorgangActions.loadVorgangWithEingangFailure,
    (state: VorgangState, props: ApiErrorAction): VorgangState => ({
      ...state,
      vorgangWithEingang: createErrorStateResource(props.apiError),
    }),
  ),
  on(
    VorgangActions.setReloadVorgangWithEingang,
    (state: VorgangState): VorgangState => ({
      ...state,
      vorgangWithEingang: { ...state.vorgangWithEingang, reload: true },
    }),
  ),

  on(
    VorgangActions.loadAttachmentList,
    (state: VorgangState): VorgangState => ({
      ...state,
      attachmentList: { ...state.attachmentList, loading: true },
    }),
  ),
  on(
    VorgangActions.loadAttachmentListSuccess,
    (state: VorgangState, props: LoadBinaryFileListSuccessProps): VorgangState => ({
      ...state,
      attachmentList: createStateResource(props.binaryFileList),
    }),
  ),

  on(
    VorgangActions.loadRepresentationList,
    (state: VorgangState): VorgangState => ({
      ...state,
      representationList: { ...state.representationList, loading: true },
    }),
  ),
  on(
    VorgangActions.loadRepresentationListSuccess,
    (state: VorgangState, props: LoadBinaryFileListSuccessProps): VorgangState => ({
      ...state,
      representationList: createStateResource(props.binaryFileList),
    }),
  ),

  on(
    VorgangActions.loadPendingCommandListSuccess,
    (state: VorgangState, props: LoadCommandListSuccessProps): VorgangState => ({
      ...state,
      forwardPendingCommand: createStateResource(getPendingCommandByOrder(props.commandList, [CommandOrder.REDIRECT_VORGANG])),
      sendPostfachNachrichtPendingCommand: createStateResource(
        getPendingCommandByOrder(props.commandList, [CommandOrder.SEND_POSTFACH_NACHRICHT]),
      ),
    }),
  ),

  on(
    VorgangActions.setForwardingSingleCommandLoading,
    (state: VorgangState): VorgangState => ({
      ...state,
      forwardPendingCommand: createEmptyStateResource(true),
    }),
  ),
  on(
    VorgangActions.setForwardingSingleCommand,
    (state: VorgangState, props: CommandStateResourceProps): VorgangState => ({
      ...state,
      forwardPendingCommand: props.commandStateResource,
    }),
  ),

  on(
    VorgangActions.setSendPostfachNachrichtSingleCommandLoading,
    (state: VorgangState): VorgangState => ({
      ...state,
      sendPostfachNachrichtPendingCommand: createEmptyStateResource(true),
    }),
  ),
  on(
    VorgangActions.setSendPostfachNachrichtSingleCommand,
    (state: VorgangState, props: CommandStateResourceProps): VorgangState => ({
      ...state,
      sendPostfachNachrichtPendingCommand: props.commandStateResource,
    }),
  ),

  on(
    VorgangActions.initAssignUser,
    (state: VorgangState): VorgangState => ({
      ...state,
      assignUserCommand: { ...state.assignUserCommand, reload: true },
    }),
  ),

  on(
    VorgangActions.exportVorgang,
    (state: VorgangState): VorgangState => ({
      ...state,
      vorgangExport: createEmptyStateResource(true),
    }),
  ),
  on(
    VorgangActions.exportVorgangSuccess,
    (state: VorgangState): VorgangState => ({
      ...state,
      vorgangExport: createStateResource(true),
    }),
  ),

  //Command
  on(CommandActions.createCommand, (state, props: CreateCommandProps): VorgangState => {
    return {
      ...state,
      statusCommandMap: VorgangReducer.getStatusCommandMapByCreateCommand(state, props.command),
      assignUserCommand: VorgangReducer.getAssignUserCommandByCreateCommand(state, props.command),
    };
  }),
  on(CommandActions.createCommandSuccess, (state, props: CommandProps): VorgangState => {
    return {
      ...state,
      statusCommandMap: VorgangReducer.getStatusCommandMapByCreateCommandSuccess(state, props.command),
      assignUserCommand: VorgangReducer.getAssignUserCommandByCreateCommandSuccess(state, props.command),
      /**
       * @deprecated Das Nachladen des Vorgangs im Service, nach erfolgreicher Beendigung des Commands, durchfuehren
       */
      vorgangWithEingang: VorgangReducer.getVorgangWithEingangStateResourceByCreateCommandSucces(state, props.command),
    };
  }),
  on(CommandActions.revokeCommand, (state): VorgangState => {
    return { ...state, revokeCommand: createEmptyStateResource(true) };
  }),
  on(CommandActions.revokeCommandSuccess, (state, props: CommandProps): VorgangState => {
    return {
      ...state,
      statusCommandMap: <any>{},
      vorgangWithEingang: { ...state.vorgangWithEingang, reload: true },
      revokeCommand: createStateResource(props.command),
    };
  }),
  on(CommandActions.publishConcurrentModificationAction, (state): VorgangState => {
    return { ...state, vorgangWithEingang: { ...state.vorgangWithEingang, reload: true } };
  }),

  //Navigation
  on(NavigationActions.updateCurrentRouteData, (state, action): VorgangState => {
    return VorgangReducer.updateByRouteData(state, action.routeData);
  }),
);

const RELOAD_VORGANG_REQUIRED_ORDER: CommandOrder[] = [
  CommandOrder.ASSIGN_USER,
  CommandOrder.PROCESS_VORGANG,
  CommandOrder.VORGANG_ANNEHMEN,
  CommandOrder.VORGANG_VERWERFEN,
  CommandOrder.VORGANG_ZURUECKHOLEN,
  CommandOrder.VORGANG_BEARBEITEN,
  CommandOrder.VORGANG_ZURUECKSTELLEN,
  CommandOrder.VORGANG_WIEDEREROEFFNEN,
  CommandOrder.VORGANG_ZUM_LOESCHEN_MARKIEREN,
  CommandOrder.LOESCH_ANFORDERUNG_ZURUECKNEHMEN,
  CommandOrder.SET_AKTENZEICHEN,
];
/**
 * @deprecated Bitte nicht mehr nutzen und die Logik im Service implementieren
 */
export function getVorgangWithEingangStateResourceByCreateCommandSucces(
  state: VorgangState,
  command: CreateCommand,
): StateResource<VorgangWithEingangResource> {
  return RELOAD_VORGANG_REQUIRED_ORDER.includes(command.order) ?
      { ...state.vorgangWithEingang, reload: true }
    : state.vorgangWithEingang;
}

export function getStatusCommandMapByCreateCommand(state: VorgangState, command: CreateCommand): StatusCommandMap {
  return isStatusCommand(command.order) ?
      { ...state.statusCommandMap, [command.order]: createEmptyStateResource(true) }
    : state.statusCommandMap;
}

export function getStatusCommandMapByCreateCommandSuccess(state: VorgangState, command: CommandResource): StatusCommandMap {
  return isStatusCommand(command.order) ?
      { ...state.statusCommandMap, [command.order]: createStateResource(command) }
    : state.statusCommandMap;
}

export function getAssignUserCommandByCreateCommand(state: VorgangState, command: CreateCommand): StateResource<CommandResource> {
  return isAssignUserCommand(command.order) ? createEmptyStateResource(true) : state.assignUserCommand;
}

export function getAssignUserCommandByCreateCommandSuccess(
  state: VorgangState,
  command: CommandResource,
): StateResource<CommandResource> {
  return isAssignUserCommand(command.order) ? createStateResource(command) : state.assignUserCommand;
}

function clearPreviewList(props: StringBasedProps): boolean {
  return isNil(props.string) || props.string === EMPTY_STRING || props.string.length <= 3;
}

export function updateByRouteData(state: VorgangState, routeData: RouteData): VorgangState {
  let newState = {
    ...state,
    vorgangList: { ...state.vorgangList, reload: true },
    vorgaenge: [],
  };

  if (isUebersichtsSeite(routeData)) {
    newState = prepareStateOnVorgangListNavigation(newState, routeData);
  }
  if (isVorgangPage(routeData)) {
    newState = prepareStateOnVorgangNavigation(newState);
  }
  return newState;
}

function prepareStateOnVorgangListNavigation(state: VorgangState, routeData: RouteData): VorgangState {
  let newState: VorgangState = {
    ...state,
    vorgangFilter: getVorgangFilter(routeData),
    vorgangView: getVorgangView(routeData),
    vorgangWithEingang: createEmptyStateResource(),
    attachmentList: createEmptyStateResource(),
    representationList: createEmptyStateResource(),
    forwardPendingCommand: createEmptyStateResource(),
    sendPostfachNachrichtPendingCommand: createEmptyStateResource(),
    statusCommandMap: <any>{},
  };

  VorgangReducer.updateLocalStorage(newState.vorgangFilter, newState.vorgangView);

  const searchString: string = getSearchString(routeData);
  newState = { ...newState, searchString };

  if (state.searchString != getSearchString(routeData)) {
    newState = { ...newState, searchPreviewList: { ...state.searchPreviewList, reload: true } };
  }

  return newState;
}

function prepareStateOnVorgangNavigation(state: VorgangState): VorgangState {
  return {
    ...state,
    vorgangWithEingang: createEmptyStateResource(),
    forwardPendingCommand: createEmptyStateResource(),
    sendPostfachNachrichtPendingCommand: createEmptyStateResource(),
  };
}

export function updateLocalStorage(vorgangFilter: VorgangFilter, vorgangView: VorgangView): void {
  setFilterIntoStorage(ROUTE_PARAM_BY_VORGANG_FILTER[vorgangFilter]);

  if (vorgangView === VorgangView.VORGANG_LIST) {
    removeLocalStorageView();
  } else {
    setViewIntoStorage(ROUTE_PARAM_BY_VORGANG_VIEW[vorgangView]);
  }
}

export function reducer(state: VorgangState, action: Action): VorgangState {
  return vorgangReducer(state, action);
}
