/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { POSTFACH_NACHRICHT_UPLOADED_ATTACHMENTS } from '@admin-client/postfach-shared';
import { BinaryFileService } from '@alfa-client/binary-file-shared';
import {
  CommandResource,
  CommandService,
  doIfCommandIsDone,
  hasCommandError,
  isDone,
  isPending,
} from '@alfa-client/command-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import {
  createEmptyStateResource,
  createStateResource,
  doIfLoadingRequired,
  isLoaded,
  isNotNull,
  isNotUndefined,
  StateResource,
} from '@alfa-client/tech-shared';
import { SnackBarService } from '@alfa-client/ui';
import { VorgangResource, VorgangService, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { hasLink, Resource } from '@ngxp/rest';
import { isNil, isNull } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';
import { PostfachFacade } from './+state/postfach.facade';
import { PostfachMailLinkRel, PostfachMailListLinkRel } from './postfach.linkrel';
import { PostfachMessages } from './postfach.message';
import {
  CreatePostfachMailCommand,
  PostfachFeatures,
  PostfachMail,
  PostfachMailListResource,
  PostfachMailResource,
  PostfachSettings,
} from './postfach.model';
import { PostfachRepository } from './postfach.repository';
import { createResendPostfachMailCommand, createSendPostfachMailCommand } from './postfach.util';

@Injectable({ providedIn: 'root' })
export class PostfachService {
  private readonly repository = inject(PostfachRepository);
  private readonly commandService = inject(CommandService);
  private readonly navigationService = inject(NavigationService);
  private readonly vorgangService = inject(VorgangService);
  private readonly snackbarService = inject(SnackBarService);
  private readonly dialog = inject(MatDialog);
  private readonly postfachFacade = inject(PostfachFacade);
  private readonly binaryFileService = inject(BinaryFileService);

  private readonly isPollSendPostachMail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  postfachMailList$: BehaviorSubject<StateResource<PostfachMailListResource>> = new BehaviorSubject<
    StateResource<PostfachMailListResource>
  >(createEmptyStateResource<PostfachMailListResource>());

  private navigationSubscription: Subscription;

  private sendPostfachMailSubscription: Subscription;
  private loadPostfachMailSubscription: Subscription;
  private vorgangSubscription: Subscription;
  private postfachNachrichtenListSubscription: Subscription;

  private vorgangChangeSubscription: Subscription;

  shouldReload: boolean = false;

  constructor() {
    this._listenToNavigation();
    this._listenToVorgangChange();
  }

  public sendMail(postfachMail: PostfachMail): Observable<StateResource<CommandResource>> {
    return this._doSendNachricht(
      this.postfachMailList$.value.resource,
      PostfachMailListLinkRel.SEND_POSTFACH_MAIL,
      createSendPostfachMailCommand(postfachMail),
    );
  }

  public sendExistingMail(
    postfachMailResource: PostfachMailResource,
    postfachMail: PostfachMail,
  ): Observable<StateResource<CommandResource>> {
    return this._doSendNachricht(postfachMailResource, PostfachMailLinkRel.SEND, createSendPostfachMailCommand(postfachMail));
  }

  public resendMail(postfachMailResource: PostfachMailResource): Observable<StateResource<CommandResource>> {
    return this._doSendNachricht(
      postfachMailResource,
      PostfachMailLinkRel.RESEND_POSTFACH_MAIL,
      createResendPostfachMailCommand(),
    );
  }

  _doSendNachricht(
    resource: Resource,
    linkRel: string,
    command: CreatePostfachMailCommand,
  ): Observable<StateResource<CommandResource>> {
    this.vorgangService.setPendingSendPostfachMailSingleCommandLoading();

    this.commandService
      .createCommand(resource, linkRel, command)
      .pipe(first())
      .subscribe((createdCommand) => this._handleSendNachrichtCommand(createdCommand));

    return this.getPendingSendPostfachMailCommand();
  }

  _handleSendNachrichtCommand(commandStateResource: StateResource<CommandResource>): void {
    this.vorgangService.setPendingSendPostfachMailCommand(commandStateResource);
    this._commandIsDone(commandStateResource);
  }

  _commandIsDone(commandStateResource: StateResource<CommandResource>): void {
    doIfCommandIsDone(commandStateResource.resource, () => {
      this.showSnackbar(commandStateResource.resource);
      this._handleSendPostfachMailIsDone(commandStateResource);
    });
  }

  private showSnackbar(commandResource: CommandResource): void {
    if (hasCommandError(commandResource)) {
      this.snackbarService.showError(PostfachMessages.SEND_FAILED);
    } else {
      this.snackbarService.show(commandResource, PostfachMessages.SEND_SUCCESSFUL);
    }
  }

  public getPendingSendPostfachMailCommand(): Observable<StateResource<CommandResource>> {
    return this.vorgangService
      .getPendingSendPostfachMailCommand()
      .pipe(map((pendingCommand) => this._pollSendPostfachMailCommand(pendingCommand)));
  }

  _listenToNavigation(): void {
    this._unsubscribeToNavigation();
    this.navigationSubscription = this.navigationService.urlChanged().subscribe((params) => this._onNavigation(params));
  }

  _unsubscribeToNavigation(): void {
    if (!isNil(this.navigationSubscription)) this.navigationSubscription.unsubscribe();
  }

  _onNavigation(params: Params): void {
    if (NavigationService.isVorgangListPage(params)) {
      this._setPollingFalse();
      this._clearPostfachMailList();
      this._closeOpenDialogs();
      this._unsubscribe();
    }
    if (NavigationService.isVorgangDetailPage(params, VorgangService.VORGANG_WITH_EINGANG_URL)) {
      this._setPostfachMailOnReload();
    }
    if (this.navigationService.isPostfachPage()) {
      this._resetHasNewPostfachNachrichten();
    }
  }

  _setPollingFalse(): void {
    this.isPollSendPostachMail.next(false);
  }

  _clearPostfachMailList(): void {
    this.postfachMailList$.next(createEmptyStateResource<PostfachMailListResource>());
  }

  _closeOpenDialogs(): void {
    this.dialog.closeAll();
    this.clearUploadedFiles();
  }

  _unsubscribe(): void {
    if (isNotUndefined(this.sendPostfachMailSubscription)) this.sendPostfachMailSubscription.unsubscribe();
    if (isNotUndefined(this.loadPostfachMailSubscription)) this.loadPostfachMailSubscription.unsubscribe();
    if (isNotUndefined(this.vorgangSubscription)) this.vorgangSubscription.unsubscribe();
  }

  _setPostfachMailOnReload(): void {
    this.postfachMailList$.next({ ...this.postfachMailList$.value, reload: true });
  }

  _resetHasNewPostfachNachrichten(): void {
    this.postfachNachrichtenListSubscription = this.getPostfachMailListByVorgang().subscribe((postfachNachrichtenList) => {
      if (isNotNull(postfachNachrichtenList.resource)) {
        setTimeout(() => this.postfachNachrichtenListSubscription.unsubscribe(), 0);
        this._doResetHasNewPostfachNachrichten();
      }
    });
  }

  _doResetHasNewPostfachNachrichten(): void {
    if (hasLink(this.postfachMailList$.value.resource, PostfachMailListLinkRel.RESET_HAS_NEW_POSTFACH_NACHRICHT)) {
      this.repository.resetHasNewPostfachNachrichten(this.postfachMailList$.value.resource).pipe(take(1)).subscribe();
    }
  }

  _pollSendPostfachMailCommand(command: StateResource<CommandResource>): StateResource<CommandResource> {
    if (this.shouldPoll(command)) {
      this._setPollingTrue();
      this.sendPostfachMailSubscription = this.commandService.pollCommand(command.resource).subscribe((updatedStateResource) => {
        this.vorgangService.setPendingSendPostfachMailCommand(updatedStateResource);

        if (isDone(updatedStateResource.resource)) {
          this._handleSendPostfachMailIsDone(updatedStateResource);
          setTimeout(() => this.sendPostfachMailSubscription.unsubscribe(), 0);
        }
      });
    }
    return command;
  }

  private shouldPoll(command: StateResource<CommandResource>): boolean {
    return command.loaded && isPending(command.resource) && !this.isPollSendPostachMail.value;
  }

  _setPollingTrue(): void {
    this.isPollSendPostachMail.next(true);
  }

  _handleSendPostfachMailIsDone(updatedStateResource: StateResource<CommandResource>): void {
    this._refreshPostfachMailList(updatedStateResource);
    this._setPollingFalse();
  }

  _refreshPostfachMailList(updatedStateResource: StateResource<CommandResource>): void {
    this._setPostfachMailListLoading();
    this._getEffectedResource(updatedStateResource)
      .pipe(first())
      .subscribe((effectedResource) => {
        this._setPostfachMailList(effectedResource);
      });
  }

  _getEffectedResource(updatedStateResource: StateResource<CommandResource>): Observable<PostfachMailListResource> {
    return this.commandService.getEffectedResource<PostfachMailListResource>(updatedStateResource.resource);
  }

  public getPostfachMailListByGivenVorgang(vorgang: VorgangResource): Observable<StateResource<PostfachMailListResource>> {
    doIfLoadingRequired(this.postfachMailList$.value, () => {
      this._setPostfachMailListLoading();
      this.loadPostfachMailsByVorgang(vorgang);
    });
    return this.postfachMailList$.asObservable();
  }

  public getPostfachMailListByVorgang(): Observable<StateResource<PostfachMailListResource>> {
    this._refreshVorgangSubscription();
    doIfLoadingRequired(this.postfachMailList$.value, () => {
      this._setPostfachMailListLoading();
      this.vorgangSubscription = this.vorgangService.getVorgangWithEingang().subscribe((vorgangWithEingangStateResource) => {
        if (vorgangWithEingangStateResource.resource) {
          this.loadPostfachMailsByVorgang(vorgangWithEingangStateResource.resource);
          setTimeout(() => this.vorgangSubscription.unsubscribe(), 0);
        }
      });
    });
    return this.postfachMailList$.asObservable();
  }

  _refreshVorgangSubscription(): void {
    this.vorgangChangeSubscription.unsubscribe();
    this._listenToVorgangChange();
  }

  _listenToVorgangChange(): void {
    this.vorgangChangeSubscription = this.vorgangService
      .getVorgangWithEingang()
      .subscribe((vorgangWithEingangStateResource: StateResource<VorgangWithEingangResource>) =>
        this._handleVorgangChange(vorgangWithEingangStateResource),
      );
  }

  _handleVorgangChange(vorgangWithEingangStateResource: StateResource<VorgangWithEingangResource>): void {
    if (this.shouldReloadList(vorgangWithEingangStateResource)) {
      this.shouldReload = true;
    }
    if (isLoaded(vorgangWithEingangStateResource) && this.shouldReload) {
      this._setPostfachMailOnReload();
      this.shouldReload = false;
    }
  }

  private shouldReloadList(vorgangWithEingangStateResource: StateResource<VorgangWithEingangResource>): boolean {
    return (
      (vorgangWithEingangStateResource.loading || vorgangWithEingangStateResource.reload) &&
      isNotNull(this.postfachMailList$.value.resource)
    );
  }

  _setPostfachMailListLoading(): void {
    this.postfachMailList$.next({ ...this.postfachMailList$.value, loading: true });
  }

  public loadPostfachMailsByVorgang(vorgang: VorgangResource): void {
    this.loadPostfachMailSubscription = this.repository.loadPostfachMailList(vorgang).subscribe((postfachMaiList) => {
      if (!isNull(postfachMaiList)) {
        this._setPostfachMailList(postfachMaiList);
        setTimeout(() => this.loadPostfachMailSubscription.unsubscribe(), 0);
      }
    });
  }

  _setPostfachMailList(postfachMailList: PostfachMailListResource): void {
    this.postfachMailList$.next(createStateResource(postfachMailList));
  }

  public isDownloadPdfInProgress(): Observable<boolean> {
    return combineLatest([this.vorgangService.getVorgangWithEingang(), this.postfachFacade.isDownloadPdfInProgress()]).pipe(
      tap(([vorgang, isDownloadInProgress]) => {
        if (isDownloadInProgress && vorgang.resource) {
          this.postfachFacade.downloadPdf(vorgang.resource);
        }
      }),
      map(([, isDownloadInProgress]) => isDownloadInProgress),
    );
  }

  public downloadPdf(): void {
    this.postfachFacade.startDownloadPdf();
  }

  public getFeatures(): Observable<PostfachFeatures> {
    return this.getPostfachMailListByVorgang().pipe(
      map((listStateResource: StateResource<PostfachMailListResource>) => listStateResource.resource.features),
    );
  }

  public getSettings(): Observable<PostfachSettings> {
    return this.getPostfachMailListByVorgang().pipe(
      map((listStateResource: StateResource<PostfachMailListResource>) => listStateResource.resource.settings),
    );
  }

  public clearUploadedFiles(): void {
    this.binaryFileService.clearUploadedFiles(POSTFACH_NACHRICHT_UPLOADED_ATTACHMENTS);
  }
}
