/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { BlobWithFileName, createEmptyStateResource, createErrorStateResource, createStateResource, EMPTY_STRING, getMessageForInvalidParam, hasStateResourceError, HttpHeader, isNotNil, isUnprocessableEntity, isValidationFieldFileSizeExceedError, sanitizeFileName, StateResource, } from '@alfa-client/tech-shared';
import { SnackBarService } from '@alfa-client/ui';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUrl, Resource, ResourceUri } from '@ngxp/rest';
import { saveAs } from 'file-saver';
import { isNil, uniqueId } from 'lodash-es';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, mergeMap, startWith } from 'rxjs/operators';
import { BinaryFileListResource, BinaryFileResource, FileUploadType, ToUploadFile, UploadFile, UploadFileByIdentifier, UploadFilesByType, } from './binary-file.model';
import { BinaryFileRepository } from './binary-file.repository';

@Injectable({ providedIn: 'root' })
export class BinaryFileService {
  _uploadFiles$: BehaviorSubject<UploadFilesByType> = new BehaviorSubject({});

  constructor(
    private repository: BinaryFileRepository,
    private snackbarService: SnackBarService,
  ) {}

  public addFiles(type: FileUploadType, binaryFileResources: BinaryFileResource[]): void {
    if (isNil(binaryFileResources)) return;
    binaryFileResources.forEach((resource: BinaryFileResource) =>
      this.setUploadedFile(this._generateUniqueId(), type, { uploadedFile: createStateResource(resource) }),
    );
  }

  public uploadFileNew(toUploadFile: ToUploadFile): void {
    this._clearFailedUploads(toUploadFile.type);
    this.createEmptyMapIfTypeNotExists(toUploadFile.type);
    const uniqId: string = this._generateUniqueId();
    this._addUploadFileLoading(uniqId, toUploadFile);
    this._doUploadFile(uniqId, toUploadFile);
  }

  _clearFailedUploads(fileUploadType: FileUploadType): void {
    const uploads: UploadFileByIdentifier = this._uploadFiles$.value[fileUploadType];
    const keys: string[] = Object.keys(uploads);
    const successfulUploads: UploadFileByIdentifier = {};
    for (const key of keys) {
      if (!hasStateResourceError(uploads[key].uploadedFile)) {
        successfulUploads[key] = uploads[key];
      }
    }
    this._uploadFiles$.next({ ...this._uploadFiles$.value, [fileUploadType]: successfulUploads });
  }

  _generateUniqueId(): string {
    return uniqueId();
  }

  _addUploadFileLoading(uniqId: string, toUploadFile: ToUploadFile): void {
    this.setUploadedFile(uniqId, toUploadFile.type, {
      fileToUpload: toUploadFile.file,
      uploadedFile: createEmptyStateResource(true),
    });
  }

  _doUploadFile(uniqId: string, toUploadFile: ToUploadFile): void {
    this.repository
      .uploadFileNew(toUploadFile.uploadUrl, toUploadFile.file)
      .pipe(
        first(),
        map((resource: BinaryFileResource) => createStateResource(resource)),
        catchError((errorResponse) => this._handleError(errorResponse.error, false)),
      )
      .subscribe((stateResource: StateResource<BinaryFileResource>) =>
        this._updateUploadedFile(uniqId, toUploadFile, stateResource),
      );
  }

  _updateUploadedFile(
    uniqId: string,
    toUploadFile: ToUploadFile,
    binaryFileStateResource: StateResource<BinaryFileResource>,
  ): void {
    this.setUploadedFile(uniqId, toUploadFile.type, {
      fileToUpload: toUploadFile.file,
      uploadedFile: binaryFileStateResource,
    });
  }

  private setUploadedFile(uniqId: string, type: FileUploadType, uploadedFile: UploadFile): void {
    this._uploadFiles$.next({
      ...this._uploadFiles$.value,
      [type]: { ...this._uploadFiles$.value[type], [uniqId]: uploadedFile },
    });
  }

  public getUploadedFiles(type: FileUploadType): Observable<UploadFileByIdentifier> {
    this.createEmptyMapIfTypeNotExists(type);
    return this._uploadFiles$.asObservable().pipe(map((files: UploadFilesByType) => files[type]));
  }

  private createEmptyMapIfTypeNotExists(type: FileUploadType): void {
    if (!(type in this._uploadFiles$.value)) this._uploadFiles$.value[type] = {};
  }

  public isUploadInProgress(type: FileUploadType): Observable<boolean> {
    return this._uploadFiles$.asObservable().pipe(
      map((files: UploadFilesByType) => Object.values(files[type] || []).map((file: UploadFile) => file.uploadedFile)),
      map((files: StateResource<BinaryFileResource>[]) =>
        files.some((stateResource: StateResource<BinaryFileResource>) => stateResource.loading),
      ),
    );
  }

  public deleteUploadedFile(type: FileUploadType, key: string): void {
    const currentMap: UploadFileByIdentifier = this._uploadFiles$.value[type];
    this._uploadFiles$.next({
      ...this._uploadFiles$.value,
      [type]: Object.keys(currentMap).reduce((acc, uploadFileKey) => {
        if (uploadFileKey !== key) acc[uploadFileKey] = currentMap[uploadFileKey];
        return acc;
      }, {}),
    });
  }

  public clearUploadedFiles(type: FileUploadType): void {
    delete this._uploadFiles$.value[type];
  }

  //TODO Rename to uploadFileOld OR refactor all use cases to uploadFileNew
  public uploadFile(
    resource: Resource,
    linkRel: string,
    file: File,
    showValidationErrorSnackBar: boolean = true,
  ): Observable<StateResource<BinaryFileResource>> {
    return this.repository.uploadFile(getUrl(resource, linkRel), file).pipe(
      mergeMap((response: HttpResponse<Object>) => this.getFile(response.headers.get(HttpHeader.LOCATION))),
      catchError((errorResponse) => this._handleError(errorResponse.error, showValidationErrorSnackBar)),
      startWith(createEmptyStateResource<BinaryFileResource>(true)),
    );
  }

  _handleError(errorResponse: HttpErrorResponse, showValidationErrorSnackBar: boolean): Observable<StateResource<any>> {
    return of(this.handleErrorByStatus(errorResponse, showValidationErrorSnackBar));
  }

  handleErrorByStatus(error: HttpErrorResponse, showValidationErrorSnackBar: boolean): StateResource<any> {
    if (isUnprocessableEntity(error.status)) {
      this.handleSnackBar(error, showValidationErrorSnackBar);
      return createErrorStateResource(error.error);
    }
    throwError({ error });
  }

  handleSnackBar(error: HttpErrorResponse, showValidationErrorSnackBar: boolean) {
    if (showValidationErrorSnackBar && isValidationFieldFileSizeExceedError(error.error)) {
      this.snackbarService.showError(getMessageForInvalidParam(EMPTY_STRING, error.error.invalidParams[0]));
    }
  }

  public downloadFile(file: BinaryFileResource, fileNamePrefix: string): Observable<StateResource<Blob>> {
    return this.repository.download(file).pipe(
      map((data) => this.saveBinaryFile(data, file, fileNamePrefix)),
      startWith(createEmptyStateResource<Blob>(true)),
      catchError(() => this.handleDownloadError()),
    );
  }

  handleDownloadError(): Observable<StateResource<Blob>> {
    this.snackbarService.showError('Die Datei konnte nicht heruntergeladen werden.');
    return of(createEmptyStateResource<Blob>());
  }

  saveBinaryFile(data: any, file: BinaryFileResource, fileNamePrefix: string): StateResource<Blob> {
    if (isNil(data)) {
      return createEmptyStateResource(true);
    }
    this.save(data, this.buildFileName(file, fileNamePrefix));
    return createStateResource(data);
  }

  private buildFileName(file: BinaryFileResource, fileNamePrefix: string): string {
    if (isNotNil(fileNamePrefix)) {
      return sanitizeFileName(`${fileNamePrefix}_${file.name}`);
    }
    return file.name;
  }

  public downloadArchive(uri: ResourceUri): Observable<StateResource<Blob>> {
    return this.repository.downloadArchive(uri).pipe(
      map((data: BlobWithFileName) => this.saveData(data)),
      startWith(createEmptyStateResource<Blob>(true)),
    );
  }

  saveData(dataWithFileName: BlobWithFileName): StateResource<Blob> {
    const data: Blob = dataWithFileName.blob;
    if (isNil(data)) {
      return createEmptyStateResource(true);
    }
    this.save(data, dataWithFileName.fileName);
    return createStateResource(data);
  }

  save(data: any, fileName: string): void {
    saveAs(data, fileName);
  }

  public getFile(uri: ResourceUri): Observable<StateResource<BinaryFileResource>> {
    return this.repository.getFile(uri).pipe(
      map((fileList: BinaryFileResource) => createStateResource(fileList)),
      startWith(createEmptyStateResource<BinaryFileResource>(true)),
    );
  }

  public getFiles(resource: Resource, linkRel: string): Observable<StateResource<BinaryFileListResource>> {
    return this.repository.getFiles(resource, linkRel).pipe(
      map((fileList: BinaryFileListResource) => createStateResource(fileList)),
      startWith(createEmptyStateResource<BinaryFileListResource>(true)),
    );
  }
}
