import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'ods-link',
  standalone: true,
  imports: [CommonModule],
  template: `<a
    [target]="openInNewTab ? '_blank' : '_self'"
    [href]="url"
    [class]="
      twMerge(
        'block rounded-md border border-transparent text-text hover:bg-neutral-100 focus-visible:border-primary focus-visible:outline-none dark:hover:bg-neutral-700',
        class
      )
    "
    [attr.data-test-id]="linkTestId"
    (focus)="isFocused = true"
    (blur)="isFocused = false"
    [class.ods-focused]="isFocused"
  >
    <ng-content />
  </a>`,
})
export class LinkComponent {
  @Input({ required: true }) url!: string;
  @Input() openInNewTab: boolean = false;
  @Input() class: string = '';
  @Input() linkTestId: string | null = null;

  public isFocused: boolean = false;
  readonly twMerge = twMerge;
}
