/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommandOrder, CreateCommand } from '@alfa-client/command-shared';
import { isNotNil } from '@alfa-client/tech-shared';
import { ResourceUri, getEmbeddedResource } from '@ngxp/rest';
import { isNull } from 'lodash-es';
import { VorgangListLinkRel } from './vorgang.linkrel';
import {
  CreateAssignUserCommand,
  CreateForwardCommand,
  ForwardRequest,
  VorgangListResource,
  VorgangResource,
} from './vorgang.model';

export function createZurueckholenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_ZURUECKHOLEN, body: null };
}

export function createAnnehmenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_ANNEHMEN, body: null };
}

export function createVerwerfenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_VERWERFEN, body: null };
}

export function createBearbeitenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_BEARBEITEN, body: null };
}

export function createBescheidenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_BESCHEIDEN, body: null };
}

export function createZurueckstellenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_ZURUECKSTELLEN, body: null };
}

export function createAbschliessenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_ABSCHLIESSEN, body: null };
}

export function createWiedereroeffnenCommand(): CreateCommand {
  return { order: CommandOrder.VORGANG_WIEDEREROEFFNEN, body: null };
}

export function createForwardCommand(redirectRequest: ForwardRequest): CreateForwardCommand {
  return { order: CommandOrder.REDIRECT_VORGANG, redirectRequest, body: null };
}

export function getVorgaengeFromList(vorgangList: VorgangListResource): VorgangResource[] {
  if (isNotNil(vorgangList)) {
    const embeddedResource: VorgangResource[] = getEmbeddedResource(vorgangList, VorgangListLinkRel.VORGANG_HEADER_LIST);
    return isNull(embeddedResource) ? [] : embeddedResource;
  }
  return [];
}

export function createAssignUserCommand(assignedTo: ResourceUri): CreateAssignUserCommand {
  return { order: CommandOrder.ASSIGN_USER, body: { assignedTo } };
}

export function isStatusCommand(order: CommandOrder): boolean {
  return [
    CommandOrder.VORGANG_ANNEHMEN,
    CommandOrder.VORGANG_BEARBEITEN,
    CommandOrder.VORGANG_BESCHEIDEN,
    CommandOrder.VORGANG_ABSCHLIESSEN,
    CommandOrder.VORGANG_VERWERFEN,
    CommandOrder.VORGANG_WIEDEREROEFFNEN,
    CommandOrder.VORGANG_ZURUECKHOLEN,
    CommandOrder.VORGANG_ZURUECKSTELLEN,
    CommandOrder.VORGANG_ZUM_LOESCHEN_MARKIEREN,
  ].includes(order);
}

export function isAssignUserCommand(order: CommandOrder): boolean {
  return order === CommandOrder.ASSIGN_USER;
}

export function createProcessVorgangCommand(processorNames: string[]): CreateCommand {
  return { order: CommandOrder.PROCESS_VORGANG, body: { processorNames } };
}
