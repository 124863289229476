/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ConvertForDataTestPipe, isNotNil } from '@alfa-client/tech-shared';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadButtonComponent } from '@ods/system';
import { uniqueId } from 'lodash-es';
import { FormControlEditorAbstractComponent } from '../formcontrol-editor.abstract.component';

@Component({
  selector: 'ods-single-file-upload-editor',
  templateUrl: './single-file-upload-editor.component.html',
  standalone: true,
  styles: [':host {@apply contents}'],
  imports: [FileUploadButtonComponent, ReactiveFormsModule, ConvertForDataTestPipe],
})
export class SingleFileUploadEditorComponent extends FormControlEditorAbstractComponent {
  @Input() label: string = '';
  @Input() accept: string = '*/*';
  @Input() uploadInProgress: boolean;

  @Output() public newFile: EventEmitter<File> = new EventEmitter<File>();

  public readonly uploadFileId: string = uniqueId();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
    if (isNotNil(event)) {
      this.newFile.emit(event.item(0));
      this.setErrors();
    }
  }
}
