/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ods-dropdown-menu-button-item',
  standalone: true,
  imports: [CommonModule],
  template: ` <div class="w-full bg-whitetext p-1.5">
    <button
      class="flex w-full items-center gap-2 rounded-md border border-transparent px-4 py-2 text-start font-medium outline-none hover:bg-background-150 focus-visible:border-primary dark:hover:bg-neutral-700"
      role="menuitem"
      (click)="clickEmitter.emit()"
      [attr.data-test-id]="dataTestId"
    >
      <ng-content select="[icon]" />
      <p class="text-sm text-primary">{{ caption }}</p>
    </button>
  </div>`,
})
export class DropdownMenuButtonItemComponent {
  @Input({ required: true }) caption!: string;
  @Input() dataTestId: string;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
}
