/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { EMPTY_STRING } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ExclamationIconComponent } from '../../icons/exclamation-icon/exclamation-icon.component';

@Component({
  selector: 'ods-error-message',
  standalone: true,
  imports: [CommonModule, ExclamationIconComponent],
  styles: [':host {@apply flex text-error my-2 text-sm items-center font-medium}'],
  template: `<ods-exclamation-icon class="mr-1"></ods-exclamation-icon>
    <div class="flex-grow break-words" [id]="id">
      {{ text }}
      <br *ngIf="subText" aria-hidden="true" />
      {{ subText }}
    </div> `,
})
export class ErrorMessageComponent {
  @Input() id: string = EMPTY_STRING;
  @Input({ required: true }) text!: string;
  @Input() subText: string = '';
}
