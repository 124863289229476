/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { SettingItem, SettingName } from '@admin-client/settings-shared';
import { createEmptyStateResource, StateResource } from '@alfa-client/tech-shared';
import { SnackBarService } from '@alfa-client/ui';
import { inject, Injectable } from '@angular/core';
import { Observable, startWith, tap } from 'rxjs';
import { PostfachResourceService } from './postfach-resource.service';
import { Postfach, PostfachResource } from './postfach.model';

@Injectable()
export class PostfachService {
  private postfachResourceService = inject(PostfachResourceService);
  private snackbarService = inject(SnackBarService);

  public get(): Observable<StateResource<PostfachResource>> {
    return this.postfachResourceService.get();
  }

  public save(postfach: Postfach): Observable<StateResource<PostfachResource>> {
    return this.postfachResourceService.save(this.buildPostfachSettingItem(postfach)).pipe(
      tap((stateResource: StateResource<PostfachResource>) => this.showInfoAfterSave(stateResource)),
      startWith(createEmptyStateResource<PostfachResource>(true)),
    );
  }

  private showInfoAfterSave(stateResource: StateResource<PostfachResource>) {
    if (!stateResource.loading) {
      this.snackbarService.showInfo('Die Signatur wurde erfolgreich gespeichert.');
    }
  }

  private buildPostfachSettingItem(postfach: Postfach): SettingItem<Postfach> {
    return {
      name: SettingName.POSTFACH,
      settingBody: postfach,
    };
  }
}
