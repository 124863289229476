import { InvalidParam, isNotEmpty, isNotNil } from '@alfa-client/tech-shared';
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessageCode } from './tech.validation.messages';

export function fieldEmptyValidator(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isNotEmpty(control.value)) return null;
    return {
      [ValidationMessageCode.FIELD_EMPTY]: {
        name: controlName,
        value: null,
        reason: ValidationMessageCode.FIELD_EMPTY,
        constraintParameters: [],
      } as InvalidParam,
    };
  };
}

export function fieldInvalidValidator(controlName: string, regex: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (regex.test(control.value)) return null;
    return {
      [ValidationMessageCode.FIELD_INVALID]: {
        name: controlName,
        value: null,
        reason: ValidationMessageCode.FIELD_INVALID,
        constraintParameters: [],
      } as InvalidParam,
    };
  };
}

export function fieldLengthValidator(controlName: string, min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    if (isNotEmpty(control.value) && value.length >= min && value.length <= max) return null;
    return {
      [ValidationMessageCode.FIELD_SIZE]: {
        name: controlName,
        value: null,
        reason: ValidationMessageCode.FIELD_SIZE,
        constraintParameters: [
          { name: 'min', value: min.toString() },
          { name: 'max', value: max.toString() },
        ],
      } as InvalidParam,
    };
  };
}

/**
 * A simplified version of validation subgroups of check boxes. Could be extended for more advanced forms.
 * It only looks up direct children of check box groups.
 */
export function checkBoxGroupsEmptyValidator(controlName: string, groupNames: string[]): ValidatorFn {
  return (control: AbstractControl<UntypedFormGroup>): ValidationErrors | null => {
    const group: UntypedFormGroup = control as UntypedFormGroup;
    const found: boolean = groupNames
      .filter((groupName: string) => _existsUntypedFormSubGroup(group, groupName))
      .map((groupName: string) => group.controls[groupName] as UntypedFormGroup)
      .map(_isAtLeastOneChecked)
      .reduce((a: boolean, b: boolean) => a || b);
    if (found) return null;
    return {
      [ValidationMessageCode.FIELD_EMPTY]: {
        name: controlName,
        value: null,
        reason: ValidationMessageCode.FIELD_EMPTY,
        constraintParameters: [],
      } as InvalidParam,
    };
  };
}

export function _existsUntypedFormSubGroup(group: UntypedFormGroup, subGroupName: string): boolean {
  return isNotNil(group.controls[subGroupName]) && group.controls[subGroupName] instanceof UntypedFormGroup;
}

export function _isAtLeastOneChecked(group: UntypedFormGroup): boolean {
  return Object.values(group.value).findIndex((isChecked: boolean) => isChecked) > -1;
}
