/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { isEmpty, isNil, uniqueId } from 'lodash-es';
import { ApiError, InvalidParam, Issue, IssueParam, ProblemDetail } from '../tech.model';
import { replacePlaceholder } from '../tech.util';
import { VALIDATION_MESSAGES, ValidationMessageCode } from './tech.validation.messages';

export function isValidationError(issue: Issue): boolean {
  return issue.messageCode.includes('javax.validation.constraints');
}

export function setIssueValidationError(form: UntypedFormGroup, issue: Issue, pathPrefix?: string): void {
  const control: AbstractControl = getControlForIssue(form, issue, pathPrefix);

  control.setErrors({ [issue.messageCode]: issue });
  control.markAsTouched();
}

export function getControlForIssue(form: UntypedFormGroup, issue: Issue, pathPrefix?: string): AbstractControl {
  const fieldPath: string = getFieldPath(issue.field, pathPrefix);

  let curControl: AbstractControl = form;
  fieldPath.split('.').forEach((field) => (curControl = (<UntypedFormGroup>curControl).controls[field]));

  return curControl;
}

export function getMessageForIssue(label: string, issue: Issue): string {
  let msg: string = VALIDATION_MESSAGES[issue.messageCode];

  if (isNil(msg)) {
    console.warn('No message for code ' + issue.messageCode + ' found.');
    return issue.messageCode;
  }

  msg = replacePlaceholder(msg, 'field', label);
  issue.parameters.forEach((param: IssueParam) => (msg = replacePlaceholder(msg, param.name, param.value)));
  return msg;
}

export function isValidationFieldFileSizeExceedError(error: ProblemDetail): boolean {
  return getMessageReason(error) === ValidationMessageCode.FIELD_FILE_SIZE_EXCEEDED;
}

export function getMessageReason(problemDetail: ProblemDetail): ValidationMessageCode | null {
  return problemDetail.invalidParams[0].reason ?? null;
}

export function getMessageCode(apiError: ApiError): string {
  return apiError.issues[0].messageCode;
}

export function setInvalidParamValidationError(form: UntypedFormGroup, invalidParam: InvalidParam, pathPrefix?: string): void {
  const control: AbstractControl = getControlForInvalidParam(form, invalidParam, pathPrefix);

  control.setErrors({ [invalidParam.reason]: invalidParam });
  control.markAsTouched();
}

export function getControlForInvalidParam(
  form: UntypedFormGroup,
  invalidParam: InvalidParam,
  pathPrefix?: string,
): AbstractControl {
  return form.get(getFieldPath(invalidParam.name, pathPrefix));
}

export function getMessageForInvalidParam(label: string, invalidParam: InvalidParam): string {
  let msg: string = VALIDATION_MESSAGES[invalidParam.reason];

  if (isNil(msg)) {
    console.warn('No message for code ' + invalidParam.reason + ' found.');
    return invalidParam.reason;
  }

  msg = replacePlaceholder(msg, 'field', label);
  invalidParam.constraintParameters.forEach((param: IssueParam) => (msg = replacePlaceholder(msg, param.name, param.value)));
  return msg;
}

export function getFieldPath(name: string, pathPrefix: string): string {
  const path: string = _mapFormArrayElementNameToPath(name);
  if (isEmpty(pathPrefix)) {
    return path;
  }

  const indexOfField = path.lastIndexOf(pathPrefix) + pathPrefix.length + 1;
  return path.slice(indexOfField);
}

export function _mapFormArrayElementNameToPath(name: string): string {
  const formArrayControlIndexCaptureGroup: RegExp = /\[(\d+?)]\./g;
  return name.replace(formArrayControlIndexCaptureGroup, '.$1.');
}

export function generateValidationErrorId(): string {
  return `${uniqueId()}-validation-error`;
}
