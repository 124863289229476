import { StateResource } from '@alfa-client/tech-shared';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SettingListResourceService } from './settings-resource.service';
import { SettingItemResource, SettingListResource, SettingName } from './settings.model';
import { getSettingItemResource } from './settings.util';

@Injectable()
export class SettingsService {
  private settingListResourceService = inject(SettingListResourceService);

  public get<T>(name: SettingName): Observable<StateResource<SettingItemResource<T>>> {
    return this.settingListResourceService
      .getList()
      .pipe(map((list: StateResource<SettingListResource>) => getSettingItemResource<T>(list, name)));
  }
}
