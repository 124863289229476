/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommandResource } from '@alfa-client/command-shared';
import { StateResource } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, SaveIconComponent, iconVariants } from '@ods/system';
import { VariantProps } from 'class-variance-authority';

type IconVariants = VariantProps<typeof iconVariants>;

@Component({
  selector: 'ods-download-button',
  standalone: true,
  imports: [CommonModule, ButtonComponent, SaveIconComponent],
  template: `<ods-button
    [dataTestId]="dataTestId"
    variant="ghost"
    size="fit"
    spinnerSize="small"
    [isLoading]="isLoading"
    (click)="clickEmitter.emit()"
  >
    <ods-save-icon icon [size]="size" class="fill-text"></ods-save-icon>
  </ods-button>`,
})
export class DownloadButtonComponent {
  @Input({ required: true }) dataTestId: string;
  @Input() size: IconVariants['size'] = 'small';
  @Input() set stateResource(resource: StateResource<CommandResource>) {
    this.isLoading = resource.loading || resource.reload;
  }

  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  isLoading: boolean = false;
}
