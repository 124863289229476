/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { GetUrlPipe, HasLinkPipe } from '@alfa-client/tech-shared';
import { NgModule } from '@angular/core';
import {
  DropdownMenuComponent,
  DropdownMenuLinkItemComponent,
  DropdownMenuTextItemComponent,
  FileIconComponent,
  HelpIconComponent,
} from '@ods/system';
import { MatFabButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';
import { DocumentationComponent } from './help-menu/documentation/documentation.component';
import { HelpButtonComponent } from './help-menu/help-button/help-button.component';
import { HelpMenuComponent } from './help-menu/help-menu.component';
import { OpenUrlButtonComponent } from '@alfa-client/ui';

@NgModule({
  imports: [
    CommonModule,
    MatMenuTrigger,
    HasLinkPipe,
    GetUrlPipe,
    MatFabButton,
    FileIconComponent,
    HelpIconComponent,
    DropdownMenuComponent,
    DropdownMenuTextItemComponent,
    DropdownMenuLinkItemComponent,
    OpenUrlButtonComponent,
  ],
  declarations: [HelpMenuComponent, DocumentationComponent, HelpButtonComponent],
  exports: [HelpMenuComponent],
})
export class UserAssistanceModule {}
