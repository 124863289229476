import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { OpenLinkIconComponent } from '../../icons/open-link-icon/open-link-icon.component';
import { LinkComponent } from '../../link/link.component';

@Component({
  selector: 'ods-dropdown-menu-link-item',
  standalone: true,
  imports: [LinkComponent, OpenLinkIconComponent, CommonModule],
  template: `<div class="w-full bg-whitetext p-1.5">
    <ods-link [url]="url" [openInNewTab]="true">
      <div class="flex min-w-80 gap-3 px-3 py-1.5">
        <ng-content select="[icon]" />
        <div class="flex flex-col gap-1">
          @if (caption) {
            <p class="text-sm font-medium text-text">{{ caption }}</p>
          }
          <div class="flex items-center gap-2">
            <p class="text-sm font-normal text-primary">{{ text }}</p>
            <ods-open-link-icon size="small" />
            <span class="sr-only">Öffnet in einem neuen Tab</span>
          </div>
        </div>
      </div>
    </ods-link>
  </div>`,
})
export class DropdownMenuLinkItemComponent {
  @Input() url: string;
  @Input() caption: string;
  @Input() text: string;
}
