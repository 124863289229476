import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ods-dropdown-menu-item',
  standalone: true,
  imports: [CommonModule],
  styles: [':host {@apply block min-h-12 px-4 py-3 first:mt-2 last:mb-2}'],

  template: `<ng-content />`,
})
export class DropdownMenuItemComponent {}
