/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ConvertForDataTestPipe } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { cva, VariantProps } from 'class-variance-authority';

export const uploadButtonVariants = cva(
  [
    'z-10 inline-flex flex-grow items-center justify-start gap-4 break-words rounded-md text-primary',
    'border border-transparent hover:bg-ghost-hover peer-focus-visible:border-background-200',
    'peer-focus-visible:outline peer-focus-visible:outline-focus peer-focus-visible:bg-ghost-hover peer-focus-visible:outline-offset-1',
  ],
  {
    variants: {
      variant: {
        label: 'py-3 px-6 bg-background-50 w-full',
        icon: 'p-2 w-fit',
      },
    },
    defaultVariants: {
      variant: 'label',
    },
  },
);
export type UploadButtonVariants = VariantProps<typeof uploadButtonVariants>;

@Component({
  selector: 'ods-file-upload-button',
  standalone: true,
  imports: [CommonModule, ConvertForDataTestPipe],
  styles: [':host {@apply relative}'],
  templateUrl: './file-upload-button.component.html',
})
export class FileUploadButtonComponent {
  @Input({ required: true }) id!: string;
  @Input() isLoading: boolean = false;
  @Input() accept: string = '*/*';
  @Input() multi: boolean = false;
  @Input() variant: UploadButtonVariants['variant'];
  @Input({ required: true }) dataTestId: string;

  @ViewChild('inputElement') inputElement: ElementRef = new ElementRef({});

  readonly uploadButtonVariants = uploadButtonVariants;

  resetInput(): void {
    this.inputElement.nativeElement.value = '';
  }
}
