<!--

    Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<input
  #inputElement
  type="file"
  [id]="id"
  class="peer absolute left-0 top-0 z-0 h-0 w-0 opacity-0"
  [accept]="accept"
  (click)="resetInput()"
  [disabled]="isLoading"
  [multiple]="multi"
  [attr.data-test-id]="dataTestId"
/>
<label [for]="id" [ngClass]="uploadButtonVariants({ variant })" role="button">
  @if (isLoading) {
    <ng-content select="[spinner]"></ng-content>
  } @else {
    <ng-content select="[icon]"></ng-content>
  }
  <div class="flex-grow empty:hidden">
    <ng-content select="[text]"></ng-content>
  </div>
</label>
