/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { Resource, getEmbeddedResource } from '@ngxp/rest';
import { isNil, isNull } from 'lodash-es';
import { LinkRelationName } from '../resource/resource.model';
import { ListResource } from '../resource/resource.util';

@Pipe({
  name: 'toEmbeddedResources',
  standalone: true,
})
export class ToEmbeddedResourcesPipe implements PipeTransform {
  transform(listResource: ListResource, linkRel: LinkRelationName): Resource[] {
    if (isNil(listResource) || isNil(linkRel)) return [];
    const embeddedReources: Resource[] = getEmbeddedResource(listResource, linkRel);
    return isNull(embeddedReources) ? [] : embeddedReources;
  }
}
