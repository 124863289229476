import { OzgcloudDialogService } from '@alfa-client/ui';
import { Component, inject, Input } from '@angular/core';
import { ButtonComponent, CloseIconComponent, TooltipDirective } from '@ods/system';

@Component({
  selector: 'ods-cancel-dialog-button',
  standalone: true,
  imports: [ButtonComponent, CloseIconComponent, TooltipDirective],
  template: ` @if (showAsIconButton) {
      <ods-button
        (clickEmitter)="cancel()"
        [tooltip]="'Schließen'"
        tooltipAriaType="aria-labelledby"
        variant="ghost"
        size="fit"
        dataTestId="cancel-dialog-icon-button"
        data-test-id="cancel-dialog-icon-button-container"
      >
        <ng-container icon>
          <ods-close-icon class="fill-primary" />
        </ng-container>
      </ods-button>
    } @else {
      <ods-button
        (clickEmitter)="cancel()"
        variant="outline"
        text="Abbrechen"
        dataTestId="cancel-dialog-button"
        data-test-id="cancel-dialog-button-container"
      >
        <ng-container icon>
          <ods-close-icon class="fill-primary" />
        </ng-container>
      </ods-button>
    }`,
})
export class CancelDialogButtonComponent {
  public readonly dialogService = inject(OzgcloudDialogService);

  @Input() showAsIconButton: boolean = false;

  public cancel(): void {
    this.dialogService.closeAll();
  }
}
