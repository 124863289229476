import { OzgCloudComponentFactory } from '@alfa-client/tech-shared';
import { DIALOG_COMPONENT, OzgcloudDialogService } from '@alfa-client/ui';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Component, ComponentRef, inject, Injector, Input, ViewContainerRef } from '@angular/core';
import { ButtonComponent, ButtonVariants } from '@ods/system';

@Component({
  selector: 'ods-open-dialog-button',
  standalone: true,
  imports: [ButtonComponent],
  template: ` <ods-button
    (clickEmitter)="open()"
    [variant]="variant"
    [text]="label"
    [size]="size"
    [dataTestId]="dataTestId"
    [dataTestClass]="dataTestClass"
    data-test-id="open-dialog"
  >
    <ng-container icon>
      <ng-content select="[icon]" />
    </ng-container>
  </ods-button>`,
})
export class OpenDialogButtonComponent {
  private readonly component: ComponentType<any> = inject(DIALOG_COMPONENT);
  private readonly componentFactory = inject(OzgCloudComponentFactory);

  private readonly dialogService = inject(OzgcloudDialogService);

  readonly viewContainerRef = inject(ViewContainerRef);
  private readonly injector = inject(Injector);

  @Input() label: string;
  @Input() dataTestId: string;
  @Input() dataTestClass: string;
  @Input() variant: ButtonVariants['variant'] = 'primary';
  @Input() dialogData: any;
  @Input() size: ButtonVariants['size'];

  public open(): void {
    this.dialogService.openInContext(this._createComponent().instance.constructor, this.viewContainerRef, this.dialogData);
  }

  _createComponent(): ComponentRef<any> {
    return this.componentFactory.createComponent<any>(this.component, this.injector, [
      { provide: DIALOG_DATA, useValue: this.dialogData },
    ]);
  }
}
