/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ConvertForDataTestPipe, generateValidationErrorId } from '@alfa-client/tech-shared';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from '@ods/system';
import { FormControlEditorAbstractComponent } from '../formcontrol-editor.abstract.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'ods-checkbox-editor',
  standalone: true,
  imports: [CheckboxComponent, ValidationErrorComponent, ReactiveFormsModule, ConvertForDataTestPipe],
  templateUrl: './checkbox-editor.component.html',
})
export class CheckboxEditorComponent extends FormControlEditorAbstractComponent {
  @Input() inputId: string;
  @Input() label: string;

  public readonly validationErrorId: string = generateValidationErrorId();

  get hasError(): boolean {
    return this.invalidParams.length > 0;
  }
}
