import { ConfigurationLinkRel, ConfigurationResource, ConfigurationService } from '@admin-client/configuration-shared';
import { ListResourceServiceConfig, ResourceListService, ResourceRepository } from '@alfa-client/tech-shared';
import { Resource } from '@ngxp/rest';
import { SettingListLinkRel } from './settings.linkrel';
import { SettingItemResource, SettingListResource } from './settings.model';

export class SettingListResourceService extends ResourceListService<
  Resource,
  SettingListResource,
  SettingItemResource<unknown>
> {}

export function createSettingListResourceService(repository: ResourceRepository, configurationService: ConfigurationService) {
  return new ResourceListService(buildConfig(configurationService), repository);
}
function buildConfig(configurationService: ConfigurationService): ListResourceServiceConfig<ConfigurationResource> {
  return {
    baseResource: configurationService.get(),
    createLinkRel: SettingListLinkRel.CREATE,
    listLinkRel: ConfigurationLinkRel.SETTING,
    listResourceListLinkRel: SettingListLinkRel.LIST,
  };
}
