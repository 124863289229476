<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ods-dropdown-menu
  buttonClass="rounded-full p-1 hover:bg-neutral-100 focus:bg-neutral-200 focus:outline-none dark:hover:bg-neutral-700 dark:focus:bg-neutral-600"
  [label]="buttonLabel"
  buttonTestId="user-icon-button"
  data-test-id="user-icon-dropdown-menu"
>
  <alfa-user-icon
    button-content
    data-test-id="user-icon"
    [userProfileStateResource]="currentUserResource"
    class="user-profile-icon"
  >
  </alfa-user-icon>
  <ods-dropdown-menu-button-item caption="Abmelden" (clickEmitter)="logoutEmitter.emit()" dataTestId="logout-button">
    <ods-logout-icon icon class="fill-primary" />
  </ods-dropdown-menu-button-item>
</ods-dropdown-menu>
