/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { SettingName, SettingsService } from '@admin-client/settings-shared';
import { ApiResourceService, ResourceRepository, ResourceServiceConfig } from '@alfa-client/tech-shared';
import { PostfachLinkRel } from './postfach.linkrel';
import { PostfachResource } from './postfach.model';

export class PostfachResourceService extends ApiResourceService<PostfachResource, PostfachResource> {}

export function createPostfachResourceService(repository: ResourceRepository, settingService: SettingsService) {
  return new ApiResourceService(buildConfig(settingService), repository);
}

function buildConfig(settingService: SettingsService): ResourceServiceConfig<PostfachResource> {
  return {
    resource: settingService.get(SettingName.POSTFACH),
    getLinkRel: PostfachLinkRel.SELF,
    edit: { linkRel: PostfachLinkRel.SELF },
  };
}
