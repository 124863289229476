/*
 * Copyright (C) 2025 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input } from '@angular/core';
import { ForwardVorgangIconComponent } from '../icons/forward-vorgang-icon/forward-vorgang-icon.component';

export enum ForwardingDirection {
  FROM = 'Weitergeleitet von',
  TO = 'An',
}

@Component({
  selector: 'ods-forwarding-item',
  standalone: true,
  imports: [ForwardVorgangIconComponent],
  template: ` <div class="h-full rounded-lg border border-grayborder">
    <div class="flex flex-col gap-3 p-3 align-top sm:flex-row">
      <div class="flex gap-3">
        <ods-forward-vorgang-icon class="fill-text" />
        <div class="font-medium text-gray-500">{{ direction }}:</div>
      </div>
      <div class="grow"><ng-content /></div>
      <div class="flex items-center"><ng-content select="[end-content]" /></div>
    </div>
  </div>`,
})
export class ForwardingItemComponent {
  @Input() direction: ForwardingDirection = ForwardingDirection.TO;
}
