/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Resource, hasLink } from '@ngxp/rest';
import { Base64 } from 'js-base64';
import { isEmpty, isNil, isNull, isUndefined } from 'lodash-es';
import { sanitize } from 'sanitize-filename-ts';
import { LinkRelationName } from './resource/resource.model';

import { ApiError } from './tech.model';

export const EMPTY_STRING: string = '';

export function getBaseUrl(): string {
  const { protocol, host } = window.location;
  const basePath = `${protocol}//${host}`;
  return basePath.endsWith('/') ? `${basePath}` : `${basePath}/`;
}

export function isEmptyObject(obj: any): boolean {
  return Object.keys(obj).length === 0;
}

export function replacePlaceholders(text: string, placeholders: { [key: string]: string }): string {
  let replaced: string = text;
  Object.keys(placeholders).forEach((key: string) => (replaced = replacePlaceholder(replaced, key, placeholders[key])));
  return replaced;
}

export function replacePlaceholder(text: string, placeholder: string, value: string): string {
  const regex: RegExp = new RegExp('{' + placeholder + '}', 'g');
  return text.replace(regex, value);
}

export function hasExceptionId(apiError: ApiError): boolean {
  return isNotNil(apiError) && isNotNil(apiError.issues) && isNotNil(apiError.issues[0].exceptionId);
}

export function sleep(delayInMs: number): void {
  const start: number = new Date().getTime();
  while (new Date().getTime() < start + delayInMs);
}

export function getFirstLetter(value: string): string {
  return isNil(value) ? null : value.substring(0, 1);
}

export function isNotEmpty(value: any): boolean {
  return !isEmpty(value);
}

export function isNotNil(value: any): boolean {
  return !isNil(value);
}

export function isNotNull(value: any): boolean {
  return !isNull(value);
}

export function isNotUndefined(value: any): boolean {
  return !isUndefined(value);
}

export function hasMinLength(value: any, length: number): boolean {
  return isNotNil(value) && value.length >= length;
}

export function hasContent(value?: string): boolean {
  return hasMinLength(value?.trim(), 1);
}

export function convertForDataTest(value: string): string {
  value = replaceAllWhitespaces(value, '_');
  return simpleTransliteration(value);
}

export function replaceAllWhitespaces(value: string, replaceWith: string): string {
  return value.replace(/ /g, replaceWith);
}

export function simpleTransliteration(value: string): string {
  return value.normalize('NFKD').replace(/[^-A-Za-z0-9_]/g, '');
}

export function getStringValue(value: null | undefined | string): string {
  if (isNil(value)) {
    return EMPTY_STRING;
  }
  return value;
}

export function encodeUrlForEmbedding(url: string): string {
  return Base64.encodeURI(url);
}

export function decodeUrlFromEmbedding(url: string): string {
  return Base64.decode(url);
}

export function sanitizeFileName(value: any): string {
  return sanitize(value);
}

export function allEmpty(value: string, ...values: string[]): boolean {
  return [...values, value].filter((value) => isNotEmpty(value)).length === 0;
}

export function isClipboardReadSupported(): boolean {
  return isNotNil(navigator.clipboard.readText);
}

export function convertToBoolean(booleanStr: string): boolean {
  try {
    return JSON.parse(booleanStr.toLowerCase());
  } catch (e: unknown) {
    console.debug('Error parsing ' + booleanStr + ' to boolean value.', e);
    return undefined;
  }
}

export function joinAndTrim(inputStrings: string[], delimiter: string = ' ') {
  return inputStrings.join(delimiter).trim();
}

export function notHasAnyLink(resource: Resource, ...links: LinkRelationName[]): boolean {
  return !hasAnyLink(resource, ...links);
}

export function hasAnyLink(resource: Resource, ...links: LinkRelationName[]): boolean {
  return !isEmpty(links.map((link: LinkRelationName) => hasLink(resource, link)).filter((hasLink: boolean) => hasLink === true));
}
